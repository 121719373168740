@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  color: #fff;
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
}

a {
  text-decoration: none;
  color: unset;
}

ul,
li {
  list-style-type: none;
}

.aviso {
  height: 100%;
  width: 60%;
  align-items: center;
  margin-top: 8%;
}

p {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: justify;
  text-justify: inter-word;
}

h5 {
  font-weight: normal;
}

h3 {
  font-weight: normal;
}

h1 {
  font-size: 4rem;
}

.mlogo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 30%;
}

.home {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 30%;
  width: 90%;
}

.mtext {
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
  text-justify: inter-word;
  width: 90%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.mhead {
  margin-top: 50px;
  margin-bottom: 20px;
  text-align: center;
  text-justify: inter-word;
  width: 90%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.mfooter {
  margin-top: 80px;
  text-align: left;
  text-justify: inter-word;
  width: 90%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.contact {
  position: absolute;
  width: 23vw;
  height: 24vh;
  /*padding: 1vh .5vw .5vw 1vh;*/
  margin-top: 50px;
  border-radius: 2px;
  background: url("../public/texture.jpg");
  background-size: cover;
  box-shadow: /* top shadow */ 0px -10px 10px rgba(0, 0, 0, 0.2),
    /* bottom shadow */ 0px 10px 20px rgba(0, 0, 0, 0.4),
    /* left shadow */ -10px 0px 20px rgba(0, 0, 0, 0.4),
    /*right shadow */ 10px 0px 20px rgba(0, 0, 0, 0.4),
    /* inner shadow */ inset 1px 1px 20px rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 0.5s;
}

.logoFinal {
  width: 120px;
  height: 120px;
  background: url("../public/gold-texture.jpg");
  background-size: cover;
  mask: url("../public/logo.png");
  -webkit-mask: url("../public/logo.png");
}

.company-text {
  font-size: 26px;
  letter-spacing: 2px;
  word-spacing: 6px;
  padding-top: 20px;
  color: transparent;
  background: url("../public/gold-texture.jpg");
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
}

.contact.back .left {
  position: absolute;
  left: 30px;
  top: 25px;
  color: transparent;
  font-weight: 700;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}

.name-text {
  font-size: 2.5vh;
  word-spacing: 8px;
  background: url("../public/silver-texture.jpg");
  background-clip: text;
  -webkit-background-clip: text;
  background-size: cover;
}

.role-text {
  font-size: 2vh;
  background: url("../public/gold-texture.jpg");
  background-clip: text;
  -webkit-background-clip: text;
  background-size: cover;
}

.contact.back .right {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 1px;
  width: 12vw;
  bottom: 20px;
  font-size: 1.9vh;
  font-weight: 700;
  color: transparent;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  background: url("../public/silver-texture.jpg");
  background-clip: text;
  -webkit-background-clip: text;
  background-size: cover;
}

.contact.front {
  transform: rotateZ(-7deg);
  top: 15vh;
  left: 30vw;
}

.contact.back {
  transform: rotateZ(4deg);
  top: 50vh;
  left: 50vw;
}

.contact:hover {
  transform: scale(1.07);
}

.light {
  position: absolute;
  width: 110vw;
  height: 110vh;
  border-radius: 50%;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.5) 20%,
    rgba(255, 255, 255, 0) 60%
  );
  opacity: 0.4;
  z-index: 9;
  pointer-events: none;
}

.five {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background: url("../public/bgimg0.jpg");
  background-size: cover;
}
